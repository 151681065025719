@import './../../../../scss/theme-bootstrap';

.footer-quick-link {
  padding: 0;
  @include breakpoint($landscape-up) {
    padding: 0 30px;
  }
  &__media {
    padding-bottom: 10px;
    @include breakpoint($landscape-up) {
      padding: 0 15px 15px;
    }
    img {
      height: 30px;
      width: auto;
      @include breakpoint($landscape-up) {
        height: 40px;
      }
    }
  }
  &__title {
    font-size: 14px;
    font-family: $font--avenir-medium;
    @include breakpoint($landscape-up) {
      font-size: 19px;
    }
    p {
      margin: 0;
      line-height: inherit;
    }
  }
  &.extole-zone {
    cursor: pointer;
  }
}
